export default class ScrollMagician {
	static controller = null;
	static scenes = [];

	static init() {
		this.controller = new ScrollMagic.Controller();
	}
	static start() {
		$('[data-magic]').each((i, item) => {
			const scene = new ScrollMagic.Scene({
				triggerElement: item,
				triggerHook: 'onEnter',
				offset: 100
			})
			scene.on('enter', function () {
				$(item).addClass('is-active');
			})
			scene.addTo(this.controller);
			this.scenes.push(scene);
		});
	}
}