import ScrollMagician from "./modules/_ScrollMagician";

class Home {
	constructor() {
		this.$view = $('#home');
		this.slider = new Home.Slider(this);
		this.sections = [];

		this.$view.find('.section').each((i, view) => {
			this.sections.push(new Home.Section($(view)));
		});
		window.common.global.loading.loaded.done(() => {
			setTimeout(() => {
				this.slider.play()
			}, 2000);
			setTimeout(() => {
				this.sections.forEach(section => {
					section.start();
				});
			}, 500);
		})
	}
	static Slider = class {
		constructor(controller) {
			this.controller = controller;
			this.$view = this.controller.$view.find('.slider');
			this.init();
			this.stop();
		}
		init() {
			this.swiper = new Swiper(this.$view.get(0), {
				speed : 2000,
				effect : 'fade',
				fadeEffect : {
					crossFade: true
				},
				autoplay : {
					delay : 2000,
					disableOnInteraction : false,
				},
				pagination: {
					el: '.swiper-pagination',
					type: 'bullets',
				}
			});
		}
		stop() {
			this.swiper.autoplay.stop();
		}
		play() {
			this.swiper.autoplay.start();
		}
	}
	static Section = class {
		constructor($view) {
			this.$view = $view;
			this.$view.line = this.$view.find('.line');
			this.$view.line.svgs = this.$view.line.find('svg');
			this.scenes = [];

			this.$view.line.svgs.each((i, svg) => {
				$(svg).drawsvg({
					duration : 900,
					stagger : 600,
					reverse : $(svg).parent().hasClass('reverse')
				});
				const scene = new ScrollMagic.Scene({
					triggerElement : svg,
					triggerHook : 'onEnter',
					offset: 100
				});
				scene.enterFunction = function() {
					setTimeout(() => {
						if(!$(svg).hasClass('animated')) {
							$(svg).addClass('animated').drawsvg('animate');
						}
					}, 600)
				}
				this.scenes.push(scene);
			});
		}
		start() {
			this.scenes.forEach(scene => {
				scene.on('enter', scene.enterFunction);
				scene.addTo(window.common.modules.ScrollMagician.controller);
			})
		}
	}
}

window.home = new Home();